import './App.css';
import react, {useState} from 'react';

// Det her skal hentes fra en fil som laves når billederne konverteres.
const Characters = [
    "11215568.asmund.JPG",
    "7687884.felichia.JPG",
    "13555681.avalon.JPG",
    "8606213.liliha.PNG",
]

// Det her skal hentes fra en fil som laves når billederne konverteres.
const SchleichPhotos = [
    "IMG_2624.JPG",
    "_DSC0024.JPEG",
    "_DSC0029.JPEG",
    "IMG_2707.JPG",
    "IMG_1821.PNG",
    "_DSC0012.JPEG",
    "E8811388-7FB6-4C4B-8303-A705302B08CB.PNG",


]

// Det her skal hentes fra en fil som laves når billederne konverteres.
const DigitalArt = [
    "IMG_2672.JPG",
    "IMG_2673.JPG",
    "IMG_2693.JPG",
    "IMG_2702.JPG",
    "IMG_2703.JPG",
    "IMG_2581.PNG",
    "IMG_2595.PNG",
    "IMG_2625.PNG",
    "IMG_2630.JPG",
    "IMG_2639.JPG",
    "IMG_2579.PNG",
];


function DBillede(props) {
    return <div>
        <img alt={props.Dkanninlort} onClick={() => props.setDZoom(props.Dkanninlort)} className={"billede"}
             src={"billeder/tpi/ikoner/" + props.Dkanninlort}/>

    </div>

}

function BBillede(props) {
    return <div>
        <img alt={props.Bkanninlort} onClick={() => props.setBZoom(props.Bkanninlort)} className={"billede"}
             src={"billeder/bas/ikoner/" + props.Bkanninlort}/>

    </div>
}

function CBillede(props) {
    // .slice() kan bruges til at fjerne en del af noget tekst.

    return <div> <a href={"https://toyhou.se/"+props.Ckanninlort.slice(0,-4)}>
        <img alt={props.Ckanninlort} className={"billede"}
             src={"billeder/tpp/ikoner/" + props.Ckanninlort}/>
    </a> </div>
}

function App() {

    const [side, setSide] = useState("tpi");
    const [DzoomedeBillede, setDZoomedeBillede] = useState();
    const [BzoomedeBillede, setBZoomedeBillede] = useState();

    return (
        <react.Fragment>
            <div className="App">
                <div className={"indhold"}>
                    <h1>Lindegaarden art</h1>
                    <div className="main">Hi, this is the website for Lindegården art</div>
                    <div className={"menu"}>
                        <span onClick={() => setSide("tpp")}>CHARACTERS</span>
                        <span onClick={() => setSide("tpi")}>DIGITAL ART</span>
                        <span onClick={() => setSide("bas")}>MODEL HORSES</span>
                    </div>

                    {side === "tpp" && <div className={"beskrivelse"}>
                        Here you can see some of my characters, pressing their image will get you directly to their toyhou.se profile
                        {Characters.map(billedeNavn => <CBillede Ckanninlort={billedeNavn}/>)}
                    </div>}

                    {side === "tpi" && <div className={"beskrivelse"}>
                        <div> Here you can see some examples of my digital art.</div>
                        {DigitalArt.map(DigitalDKanninlort => <DBillede Dkanninlort={DigitalDKanninlort}
                                                                        setDZoom={setDZoomedeBillede}/>)}
                    </div>}
                    {side === "bas" && <div className={"beskrivelse"}>
                        Here is some of my model horse pictures, mainly of Schleich horses.
                        {SchleichPhotos.map(SchleichBKanninlort => <BBillede Bkanninlort={SchleichBKanninlort}
                                                                             setBZoom={setBZoomedeBillede}/>)}
                    </div>}
                </div>
            </div>
            {DzoomedeBillede !== undefined &&
            <img alt={DzoomedeBillede} onClick={() => setDZoomedeBillede(undefined)} className={"billede_zoom"}
                 src={"billeder/tpi/store/" + DzoomedeBillede}/>}

            {BzoomedeBillede !== undefined &&
            <img alt={BzoomedeBillede} onClick={() => setBZoomedeBillede(undefined)} className={"billede_zoom"}
                 src={"billeder/bas/store/" + BzoomedeBillede}/>}

        </react.Fragment>
    );

}


export default App;
